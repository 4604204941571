@keyframes err{
  0%,
  100%{
    transform: translateX(0);
 }

  12.5%{
    transform: translateX(12px);
 }

  25%{
    transform: translateX(-12px);
 }

  37.5%{
    transform: translateX(8px);
 }

  50%{
    transform: translateX(-8px);
 }

  62.5%{
    transform: translateX(5px);
 }

  75%{
    transform: translateX(-5px);
 }

  87.5%{
    transform: translateX(3px);
 }
}

@keyframes spin{
  0%{
    transform: rotate(0);
 }
  100%{
    transform: rotate(360deg);
 }
}

@keyframes spinBanner{
  0%{
    transform: translateX(-50%) rotate(0);
 }
  100%{
    transform: translateX(-50%) rotate(360deg);
 }
}

@keyframes inputIn{
  0%{
    display: block;
  }
  100%{
    opacity: 1;display: block;font-size: 11px;top: 2%;transform: translate(0,2px);
  }
}

@keyframes inputOut{
  0%{
    display: block;
  }
  99%{
    display: block;
  }
  100%{
    display: none;
  }
}

@keyframes upTitleBox{
  from {
    z-index: 3;
  }

  to {
    z-index: 1;
  }
}

@keyframes filpUp{
  0% {
    transform: rotateX(0deg);
  }

  25% {
    transform: rotateX(-90deg);
  }

  100% {
    transform: rotateX(-90deg);
  }
}

@keyframes filpDown{
  0% {
    transform: rotateX(90deg);
  }

  25% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

@keyframes shake{
	0% {
		transform: translateY(-1%);
	}
	100% {
		transform: translateY(3%);
	}
}

@keyframes floorMove{
	0% {
		background-position-x: 0
	}
	100% {
		background-position-x: 30px
	}
}



.popup_cover{width: 100%;height: 100%;background: rgba(0, 0, 0, 0.6);opacity: 0;transition: opacity 0.2s}

.gTextColor{color:#73F7CA !important}
.yTextColor{color:#FFC453 !important}
.rTextColor{color:#F04C41 !important}
.boldText{font-weight: 700 !important}
.backgroundImg{background-repeat: no-repeat;background-position: center;background-size: cover}

/*bottomErrMsg*/
.bottomErrMsg{position: fixed;bottom: 114px;transform: translateX(-50%);max-width: 420px;text-align: center;padding: 10px;color: #fff;font-size: 14px;font-weight: 500;opacity: 0;background: rgba(58, 58, 58, 0.8);border-radius: 100px;left: 50%;width: calc(100% - 40px);display: none;transition: opacity 0.2s;z-index: 9999}
.bottomErrMsg.active{opacity: 1}

/*loarding*/
.loadingBox{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9999;display: none}
.loadingBox.active .popup_cover{transition: opacity 0s;opacity: 1}
.loading{position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);width: 80px;height: 80px;background: #19191B;border-radius: 100%;display: flex;flex-direction: column;align-items: center;justify-content: center;box-shadow: 0 0 11px 0 rgba(255, 255, 255, 0.25)}
.loadingImg{margin: 8px 0 0;position: relative;width: 50px;height: 19px}
.loading_carImg{width: 50px;position: absolute;top: 50%;left: 50%;margin: -16px 0 0 -25px;animation: shake 0.2s ease-in-out infinite alternate}
.loading_wheelImg{width: 40px;position: absolute;top: 50%;left: 50%;transform: translate(-50%, 0%);z-index: 1}
.floorItemBox{width: 93%;overflow: hidden}
.floorItem:nth-child(1){background-image: radial-gradient(rgba(255, 255, 255, 0.5) 40%, transparent 0), radial-gradient(rgba(255, 255, 255, 0.5) 25%, transparent 0);background-size: 32px 12px, 12px 12px;background-position: 0 -4.5px, 0 -4.2px;width: 100%;display: block;height: 3px;animation: floorMove 0.25s linear infinite}

/*err*/
.pageErrBox{text-align: center;padding: 70px 20px}
.pageErrTitle{font-size: 24px;font-weight: 700}
.pageErrText{margin: 14px 0 0;font-size: 14px}

/*page*/
.pageTitleBox{width: 100%;padding: 22px 20px}
.pageTitle{color: #fff;font-size: 22px;font-weight: 700}
.pageSubTitle{font-weight: 500;margin: 4px 0 0;color: rgba(255, 255, 255, 0.8);font-size: 14px}
.pageSubTitleBtn{color: #73F7CA;font-size: 14px;font-weight: 500;margin: 14px 0 0;text-decoration: underline}

/*header*/
.header{position: fixed;top: 0;left: 0;width: 100%;height: 54px;z-index: 1000}
.headerSection{height: 100%;padding: 0 20px;position: relative;background: #19191B}
.headerSection.notBg{background: transparent}
.btnHeaderRight{position: absolute;right: 20px;top: 50%;transform: translateY(-50%)}
.btnHeaderRight.top{top: 20%}
.btnHeaderRightItem{display: block;color: rgba(255, 255, 255, 0.8);text-decoration: underline;font-size: 14px;font-weight: 500}
.btnHeaderLeft{position: absolute;left: 20px;top: 50%;transform: translateY(-50%)}
.headTitle{font-size: 16px;font-weight: 800;padding: 0 35px;display: flex;width: 100%;height: 54px;align-items: center;gap: 4px}
.headTitle.centerTitle{text-align: center;justify-content: center}
.btnHeaderRightLink{font-size: 15px;font-weight: 700;color: #ACACAC;text-decoration: underline;text-align: right}
.btnAlarmLink.active::before{content: "";position: absolute;width: 6px;height: 6px;border: 3px solid #19191B;border-radius: 100%;right: -4px;top: -3px;background: #F04C41}

/*contentsSection*/
.contentsSection.bottomContents{display: flex;align-items: center;flex-direction: column;justify-content: space-between;min-height: 100vh}
.contentsSection.headerContents{padding-top: 54px}
.contentsSection.btnContents{padding-bottom: 55px}
.contentsSection.footerContents{padding-bottom: 60px}
.contentsSection.headerTapContents{padding-top: 98px}
.contentsSection.bottomBasicPadding{padding-bottom: 50px}

.itemSection{padding: 20px}
.itemSection + .itemSection{border-top: 6px solid #303036}
.itemSection.notPaddingTop{padding-top: 0}
.itemSection.notPaddingSide{padding-left: 0;padding-right: 0}
.itemSection + .itemSection.minLine{border-width: 1px}

/*footer*/
.footerMenu{position: fixed;bottom: 0;left: 0;width: 100%;background: #222225;display: flex;align-items: center;justify-content: space-between;height: 60px;z-index: 1003}
.footerMenuItem{color: #6E6E77;font-size: 11px;font-weight: 600;text-align: center;display: flex;align-items: center;justify-content: flex-start;flex-direction: column;gap: 5px;width: 20%;padding: 0 10px}
.footerMenuItem.active{font-weight: 700;color: #73F7CA}

.footer{background: #222225;margin: 40px 0 0;padding: 0 0 40px}
.footer.bottomPaddingItem{padding: 0 0 82px}
.footerSection{padding: 25px 20px}
.footer_title{color: #6E6E77;font-size: 15px;font-weight: 700;display: flex;align-items: center}
.footerSection.active .footer_title>img{transform: rotate(180deg)}
.footer_text{font-size: 12px;color: #838383;margin: 9px 0 0}
.footer_infoArea{margin: 10px 0 0}
.footer_infoText{color: #838383;font-size: 12px}
.footer_infoText + .footer_infoText{margin: 6px 0 0}
.footer_infoText span{font-weight: 500;margin: 0 4px 0 0}
.footer_termsBox{margin: 15px 0 0;padding: 14px 0 0;border-top: 1px solid rgba(231, 231, 231, 0.1);display: flex}
.footer_termsItem{font-size: 13px;font-weight: 700;color: #838383}
.footer_termsItem + .footer_termsItem{margin: 0 0 0 10px}

/*tap*/
.tapBox{height: 100%;display: flex;align-items: center;gap: 14px;overflow-x: auto}
.tapItem{height: 100%;white-space: nowrap;font-size: 15px;color: #6E6E77;font-weight: 500;border-bottom: 2px solid transparent}
.tapItem.active{font-weight: 700;border-color: #73F7CA;color: #73F7CA}
.tapBox.heightSet{height: 44px}

.tapBox.headerComTap{width: 100%;position: absolute;top: 54px;left: 0;background: #19191B;height: 44px;gap: 0;overflow-x: visible;border-bottom: 1px solid #303036}
.tapBox.headerComTap .tapItem{padding: 0 10px;width:100%}
.tapBox.headerComTap .tapItem:first-child:nth-last-child(2),.tapBox.headerComTap .tapItem:first-child:nth-last-child(2) ~ .tapItem{width: 50%}
.tapBox.headerComTap .tapItem:first-child:nth-last-child(3),.tapBox.headerComTap .tapItem:first-child:nth-last-child(3) ~ .tapItem{width: 33.33%}
.tapBox.headerComTap .tapItem:first-child:nth-last-child(4),.tapBox.headerComTap .tapItem:first-child:nth-last-child(4) ~ .tapItem{width: 25%}

.subTapBox{display: flex;align-items: center;gap: 4px;overflow-x: auto;padding: 15px 20px}
.subTapItem{border-radius: 100px;color: #6E6E77;font-size: 15px;padding: 7px 16px;white-space: nowrap}
.subTapItem.active{background: #303036;font-weight: 600;color: #fff}

.popupTapBox{width: calc(100%);display: flex;align-items: center;justify-content: flex-start;gap: 14px;overflow-x: auto}
.popupTapItem{font-size: 16px;font-weight: 700;color: #44D4A3;opacity: 0.5;display: flex;align-items: center;gap: 6px}
.popupTapItem>img{min-width: 20px;width: 20px}
.popupTapItem.active{opacity: 1}

.popupTapItem>span{color: #44D4A3;white-space: nowrap}
.popupTapItem>span.rColor{color:#FF0000}
.popupTapItem>span.yColor{color:#FF9D00}

/*notItem*/
.notItemArea{display: flex;flex-direction: column;align-items: center;justify-content: center;gap: 8px;text-align: center;height: 100%;width: 100%}
.notItemArea.min{height:auto;padding: 30px 0}
.notItemArea.fullPage{min-height: 60vh}
.notItemText{font-size: 15px;font-weight: 700;color: #464650}

/*btn*/
.pageBtn{width: 100%;height: 55px;display: block;background: #73F7CA;border: 1px solid #73F7CA;color: #0B0B0C;font-weight: 700;font-size: 16px;border-radius: 0;padding: 5px 10px}
.pageBtn:disabled{background: #6E6E77;color: #464650;cursor: auto;border-color: #6E6E77}
.pageBtn.subType{border-radius: 10px;height: 45px;font-size: 15px}
.pageBtn.line{background: rgba(115, 247, 202, 0.1);color: #73F7CA}
.pageBtn.line:disabled{background: #6E6E77;border-color: #464650;color: #464650}
.btn_box.col2{display: flex;justify-content: space-between;gap: 10px}
.pageBtn.subType + .pageBtn.subType{margin: 12px 0 0}
.pageBtn.inputInBtn{margin-top: 30px}
.pageBtn.topMargin{margin-top: 20px}

.pageBtn.nonBtn{text-decoration: underline;background: transparent;border-color: transparent;height: 44px}
.pageBtn.snsBtn{border-radius: 14px;padding: 0 50px;border:0}
.pageBtn.subBtn {background: #44D4A3; border-color: #44D4A3}
.pageBtn.col2{width: calc(50%)}
.pageBtn_box{padding: 0;background: transparent;position: fixed;left: 0;bottom: 0;width: 100%;z-index: 20;display: flex}
.pageBtn_box.col2,.btn_subBox.col2{display: flex;justify-content: space-between}
/* .pageBtn_box.col2:last-child{background-color: #44D4A3} */
.chk_item + .btn_subBox{margin: 20px 0 0}
a.pageBtn{display: flex;align-items: center;justify-content: center;text-align: center}

/*subLine*/
.subLine{display: block;height: 6px;background: #F3F3F3;width:100%}
.subLine.pageFull{width: 100vw;transform: translateX(-20px)}

/*category*/
.categoryBox{width: 100vw;margin: 15px 0 15px -20px;padding: 0 20px;display: flex;overflow-x: auto}
.categoryItem{padding: 3px 8px;white-space: nowrap;display: inline-block;color: #ACACAC;font-size: 15px;font-weight: 700;border-radius: 43px}
.categoryItem.active{background: #FF7AB7;color: #fff;font-weight: 800}

/*accordion*/
.accordionTitleBox{width: calc(100% - 40px);margin: 0 auto;border-bottom: 1px solid #303036;align-items: center;padding: 20px 0;display: flex}
.accordionBox.active .accordionTitleBox{border: 0}
.accordionIcon{color: #6E6E77;font-size: 16px;font-weight: 700;min-width: 18px;text-align: left;line-height: 1.8}
.accordionBox .questionIcon{margin: 0 6px 0 0}
.accordionBox.active .questionIcon{color: #73F7CA}
.accordionTextBox{display: flex;align-items: flex-start;width: calc(100% - 5px - 11px);padding-right: 16px}
.accordionTitle{text-align: left;width: 100%;font-size: 16px;font-weight: 600;line-height: 1.8}
.accordionTextBox + img{min-width: 11px;transition: transform 0.2s,opacity 0.2s;opacity: 0.5}
.accordionBox.active .accordionTextBox + img{transform: rotate(180deg);opacity: 1}
.answerText{background: #222225;padding: 20px 20px 30px}
.accordionText{font-size: 15px;line-height: 20px;color: rgba(255, 255, 255, 0.9);width:100%}
.answerText .accordionTextBox{padding: 0;width: 100%}

/*input*/
.input_section{padding: 0 20px 50px;width: 100%}

.inputItemBox{margin: 20px 0 0;position: relative}
.inputItemBox.marginUp{margin: 30px 0 0}
.inputName{position: absolute;z-index: 1;left: 14px;top: 50%;transform: translate(-3px,calc(-50% - 1px));font-size: 15px;color: #6E6E77;opacity: 0;transition: opacity 0.1s, font-size 0.1s, top 0.1s, transform 0.1s}
.inputItemBox:not(.active) .inputName{animation: inputOut 0.1s forwards;font-size: 15px;top: 50%;opacity: 0}
.inputItemBox.active .inputName{opacity: 1;display: block;font-size: 13px;top: 4px;transform: translate(0,2px)}

.inputItem{box-shadow: 0 1px 6px 0 rgba(0,0,0,0.1);border: 1px solid transparent;border-radius: 8px;width: 100%;padding: 13px 15px;font-size: 16px;color: #fff}
.inputItemBox.active .inputItem{padding: 22px 12px 4px}
.inputItemBox.active .notName .inputItem{padding: 13px 15px}
.err .inputItem{animation: err 0.3s;border-color: #F44D4D}
.selItem .inputItem{color: #fff;background: url(/assets/images/basic/sel_icon.svg) no-repeat center right 15px;background-color: #303036;padding-right: 45px}
.selItem .inputItem:disabled{color: #838383;background-color: rgba(0,0,0,0.1)}
.inputItemBox.active .selItem .inputItem{padding-right: 45px}
.inputSet.pwBox .inputItem{padding-right: 50px}
.btn_pwCh{position: absolute;right: 8px;top: 50%;transform: translateY(-50%)}
.inputComBox{position: relative}
.inputComBox .inputItem{padding-right: 90px}
.btn_inputSubBtn{min-width: 67px;min-height: 26px;background: #c7c7c7;border-radius: 49px;color: #fff;font-size: 14px;padding: 4px;font-weight: 700;position: absolute;right: 13px;top: 50%;transform: translateY(-50%)}
.btn_inputSubBtn.pBg{background: #FF7AB7}
.cetifiedTimer{font-size: 14px;font-weight: 500;color: #73F7CA;position: absolute;right: 15px;top: 50%;transform: translateY(-50%)}
.cetifiedInput .inputItem{padding-right: 122px}
.errMsg{color: #F04C41;margin: 4px 0 0;font-size: 13px;font-weight: 500}
.inputMsgBox .errMsg{margin: 0}
.inputMsgBox{margin: 4px 0 0;display: flex;gap: 10px;justify-content: space-between}
.inputMsgBox.contentEnd{justify-content: flex-end}
.btn_inputSubItem{font-size: 13px;font-weight: 500;color: #fff;text-decoration: underline;text-align: right}
.inputCaption{margin: 4px 0 0;font-size: 13px;color: rgba(255, 255, 255, 0.6);font-weight: 500}
.inputSet{position: relative}
.notReadOnlyColor .inputItem{color: #1e1e1e;background: #fff}

.inputUnitComBox .inputItem{padding-right: 90px}
.inputUnit{font-size: 16px;font-weight: 700;color: #5D5D5D;position: absolute;top: 50%;transform: translateY(-50%);right: 15px}

.inputBox.selectIn .inputItem{padding-left: 115px}
.inputBox.selectIn .inputItem + .inputItemBox{width: 90px;margin: 0;position: absolute;top: 50%;left: 15px;transform: translateY(-50%)}
.inputItemBox.active .inputBox.selectIn .inputItem + .inputItemBox{transform: translateY(calc(-50% + 7px))}
.inputBox.selectInItem .inputItem{font-size: 13px;padding: 2px 6px !important;border-radius: 5px;background-color: #6E6E77;border-color: #6E6E77;color: #fff;display: block}
.inputBox.selectInItem .selItem .inputItem{background-size: 20px;background-position: center right 5px}
.inputBox.selectInItem .selItem .inputItem::placeholder{color:#fff}

.chk_item{padding: 2px 0;display: flex;align-items: center;justify-content: space-between;gap: 15px}
.chk_item:not(.allChkItem) + .chk_item{margin: 16px 0 0}
.chk_item.allChkItem{margin: 0 0 20px}
.chk_item.allChkItem .chk_item_text{font-weight: 600;font-size: 16px}
.chk_item label{position: relative;padding: 0 0 0 30px;width: calc(100% - 34px);font-weight: 700}
.chk_item label::before{content: "";position: absolute;left: 0;top: 50%;transform: translateY(-50%);width: 20px;height: 20px;background: url(/assets/images/basic/chk_off.svg) no-repeat center;background-size: 100%}    
.chk_item input:checked + label::before{background-image: url(/assets/images/basic/chk_on.svg)}
.chk_item_text{color: rgba(255, 255, 255, 0.8);font-size: 14px}
.chkBoxBtn{color:#6E6E77;text-decoration: underline;font-size: 14px;text-align: right;white-space: nowrap}
.inputItemBox + .chk_item{margin-top: 16px}

.toggleBox{width: 35px;min-width: 35px}
.toggleBox label{cursor: pointer;display: flex;font-weight: 500;align-items: center;justify-content: space-between}
.toggleBox label span{width: 35px;height: 20px;background: #464650;border-radius: 23px;position: relative;transition: background 0.3s}
.toggleBox label span::before{content: "";position: absolute;top: 50%;transform: translateY(-50%);left: 2px;background: rgba(255, 255, 255, 0.8);border-radius: 100%;width: 16px;height: 16px;box-shadow: 0 0 3px 0 rgba(0,0,0,0.25);transition: left 0.3s cubic-bezier(0.89, 0.11, 0.09, 0.89)}
.toggleBox .toggleChk:checked + label span{background: #44D4A3}
.toggleBox .toggleChk:checked + label span::before{left: calc(100% - 18px)}

.textareaItem{display: block;resize: none;min-height: 180px;border: 1px solid transparent;border-radius: 10px;width: 100%;padding: 12px 15px;font-size: 15px;color: #fff}
.textareaCount{font-size: 13px;width: 100%;text-align: right;padding: 8px 0 0;color: #ACACAC}
.textAreaBtnComBox{position: relative}
.textAreaBtnComBox .textareaItem{background: #fff;color: #1E1E1E;padding-right: 90px}
.textAreaBtnComBox .btn_inputSubBtn{top: 14px;transform: translateY(0)}

.inputFileList{width: 100vw;transform: translateX(-20px);padding: 0 20px;overflow-x: auto;display: flex;gap: 12px}
.inputFileBox .inputFileList label{min-width: 70px;width: 70px;height: 70px;padding: 0;border-radius: 14px;display: flex;align-items: center;justify-content: center;background: #303036}
.fileItemMultipleItem{background-repeat: no-repeat;background-size: cover;background-position: center;width: 70px;height: 70px;min-width: 70px;border-radius: 14px;position: relative}
.fileItemMultipleItemDel{position: absolute;top:5px;right:5px;z-index: 1}

.react-datepicker-wrapper{width: 100%}
.datePicerInputBox .inputItem{width: 100%;background: url(/assets/images/basic/cal.svg) no-repeat right 15px center;background-color: #fff;color: #1E1E1E}

.inputSubCaptionItem{position: relative}
.inputSubCaptionItem .inputItem{padding-left: 33px}
.inputSubCaption_text{font-size: 16px;position: absolute;left: 15px;top: 50%;transform: translateY(-50%)}

.customSelectInputBox{position: relative}
.customSelectInputBox.active::after{transform: translateY(-50%) rotate(180deg)}
.customSelectInputBox::after{content: "";width:11px;height:6px;position: absolute;background: url(/assets/images/basic/sel.svg) no-repeat center;top: 50%;transform: translateY(-50%);right: 12px}
.customSelectInputBox .inputItem{background: #303036;color: #fff;padding-right: 34px}
.customSelect_optionBox{border: 1px solid #424243;border-radius: 10px;margin: 5px 0 0;background: #303036;overflow: hidden;display: none;max-height: 200px;overflow-y: auto}
.customSelect_optionBox.active{display: block}
.customSelect_option{font-size:15px;display: block;color: rgba(255, 255, 255, 0.9);width: 100%;text-align: left;padding: 10px}
.customSelect_option.active{font-weight: 600}

.couponCustomSelectInputBox::after{content: "";width:11px;height:12px;position: absolute;background: url(/assets/images/basic/sel2.svg) no-repeat center;top: 50%;transform: translateY(-50%);right: 12px}
.couponCustomSelectInputBox .inputItem{background: #303036;color: #fff;padding-right: 34px}

.inputSubBox{display: flex;gap: 15px;align-items: flex-start;justify-content: space-between}
.inputSubBox .inputItemBox{width: calc(50% - 7.5px)}

.inputSubBoxOnly{margin: 24px 0 0}
.inputName.pageName{animation: none;transform: translate(0,0);position: static;color: #fff;font-size: 15px;font-weight: 700;opacity: 1}
.inputName.pageName + .inputItemBox{margin-top: 8px}

.inputViewBox{margin: 8px 0 0}
.inputViewText{border: 1px solid transparent;border-radius: 8px;width: 100%;padding: 13px 15px;font-size: 15px;color: #fff;background: #303036}
.inputViewBox.textAreaViewBox .inputViewText{display: block;resize: none;min-height: 180px;border-radius: 10px;padding: 12px 15px}
.fileViewBox{margin: 8px 0 0;width: 100vw;transform: translateX(-20px);padding: 0 20px;overflow-x: auto;display: flex;gap: 12px}
.fileViewBox>div{width: 70px;height: 70px;min-width: 70px;border-radius: 14px;position: relative}

/*infoTextBox*/
.infoTextBox{padding: 20px 20px 50px}
.infoText_title{font-size: 15px;font-weight: 700}
.infoText{color: rgba(255, 255, 255, 0.8);font-size: 14px;margin: 6px 0 0;line-height: 160%}
.infoTextLineChk{display: inline-block;min-width:3.05px}

.input_section .infoTextBox{padding: 20px 0}

/*datepicker*/
.react-datepicker__tab-loop{width: 100%}
.react-datepicker__tab-loop__start{display: none}
.react-datepicker-popper{padding: 0;width: 100%;z-index: 10;max-width: 320px;z-index: 25}
.react-datepicker{padding: 0;background: #19191B;border: 0;border-radius: 0;font-size: 0.875em;width: 100%;display: flex;align-items: flex-start;justify-content: space-between;gap: 20px}
.react-datepicker__day-names{margin: 0}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle{display: none}
.react-datepicker-popper[data-placement^=top]{padding-bottom: 6px}
.react-datepicker-popper[data-placement^=bottom]{padding-top: 6px}
.react-datepicker__month-container{width: 100%}
.react-datepicker__header{background: transparent;border: 0;padding: 0 0 0}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{font-weight: 700;font-size: 14px;margin: 0 0 14px;color: #fff}
.react-datepicker__day-names{font-size: 11px;display: flex;align-items: center;justify-content: space-between}
.react-datepicker__month{margin: 0}
.react-datepicker__day-names, .react-datepicker__week{display: flex;align-items: center;justify-content: space-between}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{min-width: 14.28%;margin: 0;height: 56px;display: flex;align-items: center;justify-content: center;font-weight: 500;color: #fff;line-height: 1}
.react-datepicker__day-name{height: 36px;color: #888892}
.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled{color: #464650}
.react-datepicker__day--disabled::before, .react-datepicker__month-text--disabled::before, .react-datepicker__quarter-text--disabled::before, .react-datepicker__year-text--disabled::before{display: none}
.react-datepicker__navigation{width: 21px;height: 21px;background-size: 100%;top: 0}
.react-datepicker__navigation:disabled{opacity: 0.4}
.react-datepicker__navigation--previous{left: 4px;background: url(/assets/images/basic/cal_left.svg) no-repeat center}
.react-datepicker__navigation--next{right: 4px;background: url(/assets/images/basic/cal_right.svg) no-repeat center}
.react-datepicker__navigation-icon{display: none}
.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover{background: transparent;position: relative}
.react-datepicker__day.react-datepicker__day--in-range,.react-datepicker__day.react-datepicker__day--in-selecting-range:hover{background: rgba(30, 30, 30, 0.05)}
.react-datepicker__day--disabled,.react-datepicker__day--outside-month{background: transparent !important}
.react-datepicker__day span{position:relative;z-index: 1}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range), .react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range){background: transparent}
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range):not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month){border-radius: 0;background: rgba(30, 30, 30, 0.05)}
.react-datepicker__day--in-range:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month),.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month){border-radius: 0;background: rgba(30, 30, 30, 0.05)}
.react-datepicker__day--selected:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month),
.react-datepicker__day--range-end:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month),
.react-datepicker__day--selecting-range-start:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month),
.react-datepicker__day--selecting-range-end:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month){position: relative;background: transparent !important}
.react-datepicker__day--selected:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month)::before,
.react-datepicker__day--range-end:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month)::before,
.react-datepicker__day--selecting-range-start:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month)::before,
.react-datepicker__day--selecting-range-end:not(.react-datepicker__day--disabled,.react-datepicker__day--outside-month)::before{content: "";position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);height: 36px;width: 36px;border: 1px solid #73F7CA;border-radius: 100% !important;background: #73F7CA !important;color: #0B0B0C}
.react-datepicker__day--selected span,.react-datepicker__day--range-end span,.react-datepicker__day--selecting-range-start span,.react-datepicker__day--selecting-range-end span{color:#0B0B0C;font-weight: 700}
.react-datepicker__day--keyboard-selected{background: transparent !important}
.react-datepicker__triangle{display: none}

.react-datepicker__day::before, .react-datepicker__time-name::before{content: "";position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%);height: 36px;width: 36px;border: 1px solid #303036;border-radius: 100% !important;background: #303036 !important;color: #0B0B0C}
.react-datepicker__day, .react-datepicker__time-name{color: #6E6E77;position:relative}
.itemSectionTitleBox + .inputItemBox .react-datepicker{margin-top: 20px}

/*popup*/
.popup{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9000;display: none}
.popup .popupSetion{padding: 0;overflow: hidden;position: fixed;background: #4C4C53;top: 50%;left: 50%;transform: translate(-50%,-50%);z-index: 1;border-radius: 18px;width: calc(100% - 40px);max-width: 300px;max-height: calc(100% - 40px);opacity: 0;transition: opacity 0.2s}
.popupTextBox{padding: 20px}
.btnPopupSetion .popupSetion{padding-bottom: calc(62px + 1.313em + 20px)}
.popup.active .popup_cover{opacity: 1}
.popup.active .popupSetion{opacity: 1}
.popupTitle{font-weight: 700;font-size: 18px;margin: 0 0 14px;color:#fff}
.popupTitle>p{display: inline-block;margin: 0 0 0 6px;background: #303036;border-radius: 5px;padding: 2px 6px;font-size: 13px;color: rgba(255, 255, 255, 0.6);transform: translateY(-1px)}
.popupTitle>p>span{color: rgba(255, 255, 255, 0.9)}
.popupText{font-size: 15px;color: rgba(255, 255, 255, 0.8)}
.popupText.rColor{color: #F44D4D}
.popupText .rColor{color: #F44D4D}
.popup_btnBox{display: flex;width: 100%;justify-content: space-between;border-top: 1px solid rgba(255, 255, 255, 0.1)}
.btn_popup{color: #fff;height: 52px;display: flex;align-items: center;justify-content: center;font-size: 15px;font-weight: 600;width:100%}
.btn_popup:first-child:nth-last-child(2),.btn_popup:first-child:nth-last-child(2) ~ .btn_popup{width: 50%}
.btn_popup + .btn_popup{border-left: 1px solid rgba(255, 255, 255, 0.1)}
.btn_popup[disabled=true]:not(.btn_popup[disabled=false]),.btn_popup:disabled:not(.btn_popup[disabled=false]){background: #D8D8D8;border-color: #d8d8d8;cursor: auto}
.btn_popup.line[disabled=true]:not(.btn_popup[disabled=false]),.btn_popup.line:disabled:not(.btn_popup[disabled=false]){background: #fff;border-color: #C7C7C7;color: #C7C7C7}

/*rightPopup*/
.rightPopupClose{position: absolute;top: 50%;right: 20px;transform: translateY(-50%)}
.rightPopup{background: #19191B;position: fixed;top: 0;right: 0;width: 100%;height: 100%;transform: translateX(100%);z-index: 9000;transition: transform 0.2s cubic-bezier(0.89, 0.11, 0.09, 0.89)}
.rightPopup.active{transform: translateX(0)}
.rightPopup .popup_cover{display: none}
.rightPopup_section{height: 100%;background: #19191B}
.rightPopup_header_section{height: 54px;position: relative;padding: 0 20px;display: flex;align-items: center;z-index: 10}
.rightPopup_contents{height: calc(100% - 54px);overflow-y: auto;padding: 0}
.rightPopup_title{font-size: 15px;font-weight: 500;padding: 0 25px;display: flex;width: 100%;height: 54px;align-items: center;text-align: center;justify-content: center;letter-spacing: -1px;line-height: 1.2}
.termsText{padding: 20px;font-size: 16px;line-height: 25px;white-space: pre-wrap}
.rightPopup{background: rgba(53, 53, 53, 0.9);backdrop-filter: blur(5.5px);-webkit-backdrop-filter: blur(5.5px)}

.rightPopup.bottomBtnText .rightPopup_contents{padding-bottom: 160px}
.rightPopup.bottomBtn .rightPopup_contents{padding-bottom: 60px}
.rightPopup.sidePadding .rightPopup_contents{padding-left: 20px;padding-right: 20px}

/*selPopup*/
.selPopup{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9000;display: none}
.selPopupSetion{border-top-left-radius: 20px;border-top-right-radius: 20px;padding: 20px 0 0;background: #303036;position: absolute;bottom: 0;left: 0;z-index: 1;width: 100%;transform: translateY(100%);transition: transform 0.2s cubic-bezier(0.13, 0.91, 0.11, 0.91)}
.selPopupTextBox{padding: 0 20px 10px}
.selPopup_selBox{padding: 0 0 30px;max-height: 300px;overflow-y: auto}
.selPopup_sel{color: rgba(255, 255, 255, 0.9);padding: 10px 20px;text-align: left;border-radius: 0;border: 0;display: block;width: 100%;font-size: 15px;font-weight: 400}
.selPopup.active .popup_cover{opacity: 1}
.selPopup.active .selPopupSetion{transform: translateY(0)}
.selPopup_sel.active{font-weight: 600;background: url(/assets/images/basic/selPopup_active.svg) no-repeat center right 20px}
.selPopup .popupHaeder{position: relative;padding: 0 0 14px}
.selPopup .popupHaederTitle{font-size: 18px;font-weight: 700;color: #fff;padding: 0 20px}
.popupClose{position: absolute;right: 20px;top: -2px}
.selPopup.selBgType .selPopup_sel.active{background-color:rgba(34, 34, 37, 1)}

/*toastPopup*/
.toastPopup{position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 9000;display: none}
.toastPopup .popupSetion{border-top-left-radius: 20px;border-top-right-radius: 20px;display: flex;flex-direction: column;max-height: calc(100% - 20px);background: #303036;position: absolute;bottom: 0;left: 0;z-index: 1;padding: 20px 20px 85px;width: 100%;transform: translateY(100%);transition: transform 0.2s cubic-bezier(0.13, 0.91, 0.11, 0.91)}
.toastPopup.fullContetns .popupSetion{padding: 0 0 55px}
.toastPopup.active .popup_cover{opacity: 1}
.toastPopup.active .popupSetion{transform: translateY(0)}
.toastPopup.active.notBtn .popupSetion{padding-bottom: 30px}
.toastPopup .popupHaeder{min-height: 52px;position: relative;display: flex;align-items: center;justify-content: space-between;gap: 15px}
.toastPopup .popupHaederTitle{text-align: left;font-size: 18px;font-weight: 700;color: #fff;display: flex;align-items: center;gap: 6px}
.toastPopup .popupContents{overflow-y: auto}
.textBtn{font-size: 14px;font-weight: 600;color: #44D4A3;text-align: right}
.textBtn.underLine .textBtn_contents{text-decoration: underline}
.toastPopup .popupClose{position: absolute;right: 0;top: -2px}
.textBtnItemBox{text-align: center}

.toastPopup_btnBox{position: absolute;left: 0;bottom: 0;width: 100%;min-height: 55px;border: 0;justify-content: space-between}
.toastPopup_btnBox .btn_popup{width: 100%;height: 55px;background: #73F7CA;border: 1px solid #73F7CA;color: #0B0B0C;font-weight: 700;font-size: 16px;border-radius: 0;padding: 5px 10px;display: flex;align-items: center;justify-content: center}
.toastPopup_btnBox .btn_popup.gColor{background-color: rgba(110, 110, 119, 0.6);color:#fff;font-size: 14px;font-weight: 400;border-color: rgba(110, 110, 119, 0.6)}
.toastPopup_btnBox .btn_popup.gColor + .btn_popup.gColor{border-left-color: rgba(255, 255, 255, 0.1)}
.popup_btnChkBox{min-height: 20px;margin: 20px 0 0}
.popup_btnChkBox + .toastPopup_btnBox{margin-top: 20px}

.toastBasicContents{padding: 0 0 20px}
.toastBasicContents_title{font-size: 18px;font-weight: 700}
.toastBasicContents_text{font-size: 15px;color: rgba(255, 255, 255, 0.9);margin: 2px 0 0}

/*slidePopup*/
/* .slideUpPopup{position: fixed;bottom: 60px;left: 0;width: 100%;background: #73F7CA;box-shadow: 0px 0px 16px rgb(0 0 0 / 20%);border-radius: 20px 20px 0px 0px;z-index: 1002;transform: translateY(calc(100% - 82px));transition: background 0.2s}
.slideUpPopup.active{transition: background 0.2s, transform 0.395s}
.slideUpPopup.open{background: #303036}
.scroll_sel_icon{width: 100%;height: 30px;display: flex;align-items: center;justify-content: center}
.slideUpPopup.open .scroll_sel_icon img{transition: transform 0.2s;transform: rotate(180deg)}
.slideUpPopup.open .slideUpPopup_titleBox{opacity: 0;z-index: 1;animation: upTitleBox 0.2s 0s forwards}
.slideUpPopup_titleBox{display: flex;align-items: center;transition: opacity 0.2s;opacity: 1;background: #73F7CA;width: 100%;z-index: 3;justify-content: space-between;gap: 10px;min-height: 50px;position: absolute;left: 0;top: 30px}
.slideUpPopup_contents{position: relative;width: 100%;height: calc(100% - 30px);overflow-y: auto;z-index: 2;background: transparent;opacity: 0;transition: opacity 0.2s}
.slideUpPopup.open .slideUpPopup_contents{opacity: 1}
.slideUpPopup_cover{background: rgba(0, 0, 0, 0.6);position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 1001;display: none;opacity: 0;transition: opacity 0.2s}
.slideUpPopup_cover.active{display: block;opacity: 1} */

.slideUpPopup{position: fixed;bottom: 60px;left: 0;width: 100%;background: #73F7CA;box-shadow: 0px 0px 16px rgb(0 0 0 / 20%);border-radius: 20px 20px 0px 0px;z-index: 1002;transform: translateY(calc(100% - 82px))}
.slideUpPopup.open{background: #303036}
.scroll_sel_icon{width: 100%;height: 30px;display: flex;align-items: center;justify-content: center}
.slideUpPopup.open .scroll_sel_icon img{transform: rotate(180deg)}
.slideUpPopup.open .slideUpPopup_titleBox{opacity: 0;z-index: 1}
.slideUpPopup_titleBox{display: flex;align-items: center;opacity: 1;background: #73F7CA;width: 100%;z-index: 3;justify-content: space-between;gap: 10px;min-height: 50px;position: absolute;left: 0;top: 30px}
.slideUpPopup_contents{position: relative;width: 100%;height: calc(100% - 30px);overflow-y: auto;z-index: 2;background: transparent;opacity: 0}
.slideUpPopup.open .slideUpPopup_contents{opacity: 1}
.slideUpPopup_cover{background: rgba(0, 0, 0, 0.6);position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 1001;display: none;opacity: 0}
.slideUpPopup_cover.active{display: block;opacity: 1}


/*info*/
.infoItem{position: relative;background: #464650;border-radius: 6px;padding: 6px 10px;font-size: 13px;font-weight: 700;color: #fff}
.infoItem.right::after{left: 100%;top: 50%;border: solid transparent;content: "";height: 0;width: 0;position: absolute;pointer-events: none;border-color: rgba(70, 70, 80, 0);border-left-color: #464650;border-width: 4px;margin-top: -4px}
.infoCloseBox{display: flex;align-items: center;justify-content: flex-end;gap: 10px}

/*member*/
.logoBox{width: 100%;padding: 0 20px 40px;display: flex;align-items: center;justify-content: center}
.logoBox.pageLogo{min-height: 50vh;padding: 20px 20px 60px}
.welcome_sns{margin: 30px 0;text-align: center;position: relative}
.welcome_sns::before{content: "";position: absolute;top: 50%;left: 0;width: 100%;height: 1px;background: rgba(66, 66, 67, 1)}
.welcome_sns_text{color: rgba(255, 255, 255, 0.6);text-align: center;font-size: 13px;font-weight: 500;padding: 0 20px;display: inline-block;position: relative;z-index: 1;background: #19191B}
.welcome_snsBtnBox{display: flex;align-items: center;justify-content: center;gap: 14px}

.loginSubLinkBox{display: flex;align-items: center;justify-content: space-between;margin: 30px 0 0;padding: 0 0 10px}
.loginSubLink{font-size: 13px;text-align: center;width: calc(33.33% - 0.6666px);padding: 0 10px;color: #fff}
.loginSubLinkBox span{background: #424243;height: 12px;width: 1px;display: block}
.loginContetns{width:100%}

.textBtn{display: inline-flex;align-items: center;gap: 10px;justify-content: center}
.textBtn_contents{font-size: 14px;color: rgba(255, 255, 255, 0.8)}
.textBtn_contents+img{width: 6px}
.subLogin_linkBox{text-align: center;padding: 0 20px 50px}

.mamberPageContentsBox{min-height: calc(100vh - 54px - 55px);display: flex;flex-direction: column;gap: 30px;justify-content: space-between;align-items: center;padding: 20px 20px 80px}
.mamberPageContents_titleBox{text-align: center}
.mamberPageContents_title{font-size: 24px;font-weight: 700;color: #fff}
.mamberPageContents_subTitle{margin: 14px 0 0;font-size: 15px;color: #fff}

.carNumberBox{max-width: 300px;margin: 10px auto 0;border: 2px solid #6E6E77;border-radius: 14px;background: #303036;position: relative;padding: 12px 40px}
.carNumberBox::before{content: "";position: absolute;top: 50%;transform: translateY(-50%);width: 8px;height: 8px;border-radius: 100%;background: #6E6E77;left: 15px}
.carNumberBox::after{content: "";position: absolute;top: 50%;transform: translateY(-50%);width: 8px;height: 8px;border-radius: 100%;background: #6E6E77;right: 15px}
.carNumber{border: 0 !important;font-size: 36px;font-weight: 700;width: 100%;text-align: center}
.carNumber:focus{border: 0 !important}

.carInfoPopup>img{width:100%}
.carInfoPopup_title{color: rgba(255, 255, 255, 0.9);font-size: 18px;font-weight: 600;text-align: center;margin: 10px 0 0}
.carInfoPopupDetail{margin: 20px 0 0;background-color: #222225;border-radius: 14px;padding: 15px}
.carInfoPopupDetail_item{display: flex;align-items: center;gap: 15px}
.carInfoPopupDetail_item + .carInfoPopupDetail_item{margin: 15px 0 0}
.carInfoPopupDetail_name{font-size: 13px;color: rgba(255, 255, 255, 0.6);min-width: 45px}
.carInfoPopupDetail_val{font-size: 15px;font-weight: 500;color: rgba(255, 255, 255, 0.9);width:calc(100% - 45px - 15px)}

.surveyPage{padding: 0 20px 50px}
.surveyPage_titleBox{margin: 0 0 30px}
.surveyPage_titleCaption{font-size: 14px;font-weight: 700;color: #73F7CA}
.surveyPage_title{font-size: 20px;font-weight: 700;color: #fff}
.surveyRadio_item + .surveyRadio_item{margin: 12px 0 0}
.surveyRadio_item label{border-radius: 100px;display: block;padding: 15px 20px;background: url(/assets/images/basic/sel_off.svg) no-repeat right 20px center;background-color: #303036}
.surveyRadio_item_text{font-size: 15px;font-weight: 500;color: #fff;padding-right: 24px}
.surveyRadio_item input:checked + label{background-image: url(/assets/images/basic/sel_on.svg);background-color: #73F7CA}
.surveyRadio_item input:checked + label .surveyRadio_item_text{color: #222225;font-weight: 600}
.surveyPage_footer{position: fixed;bottom: 0;left: 0;width: 100%;background: #19191B;height: 70px;padding: 12px 20px;display: flex;align-items: center;justify-content: space-between;gap: 10px}
.surveyPageBtn{min-width: 80px;display: flex;align-items: center;justify-content: center;gap: 15px;font-size: 15px;font-weight: 600;color: #73F7CA}
.surveyPageBtn:disabled{color: #464650}
.surveyPageCountBox{min-width: 40px;height: 40px}
.surveyPageCount{width:100%;height: 100%;border-radius: 100%;position: relative;transition: background 0.1s}
.surveyPageCount::before{content: "";position: absolute;width: 32px;height: 32px;background: #19191B;border-radius: 100%;top: 50%;left: 50%;transform: translate(-50%,-50%)}
.surveyResult_title{margin: 20px 0 0;text-align: center;font-size: 24px;font-weight: 700;padding: 0 20px}
.surveyResult_textBox{margin: 30px 0 0;padding: 0 20px}
.surveyResult_textBox img{margin: 0 auto 8px}
.surveyResult_text{font-size: 14px;color: rgba(255, 255, 255, 0.9);text-align: center}
.surveyResult_img{width: calc(100% - 120px);margin: 20px auto 0}
.surveyResultList{padding: 0 20px 30px}

.copyInput{position: fixed;top: 0;left: -1000px;transform: translate(-100%,-100%)}
.idFindBox{padding: 30px 0;display: flex;flex-direction: column;align-items: center;justify-content: center;gap: 15px}
.idFind_id{font-size: 28px;font-weight: 700;color: #fff}
.copyText{background: #222225;border-radius: 8px;padding: 6px 12px;font-weight: 600;font-size: 13px;color: rgba(255, 255, 255, 0.8);display: flex;align-items: center;gap: 3px}

/*topLink*/
.topLink{display: flex;align-items: center;justify-content: space-between;width: 100%;padding: 13px 20px;background: #73F7CA;gap: 20px;margin: 1px 0 0}
.topLink_name{font-weight: 700;font-size: 14px;color: #0B0B0C}
.topLink_caption{display: flex;align-items: center;justify-content: flex-end;text-align: right;gap: 6px;text-decoration: underline;font-size: 14px;font-weight: 500;color: #0B0B0C}

/*main*/
.headCarNumber{max-width: 104px;margin: 0;border: 2px solid #464650;border-radius: 6px;background: #303036;position: relative;padding: 3px 16px;font-size: 13px;font-weight: 700}
.headCarNumber::before{content: "";position: absolute;top: 50%;transform: translateY(-50%);width: 4px;height: 4px;border-radius: 100%;background: #fff;left: 6px}
.headCarNumber::after{content: "";position: absolute;top: 50%;transform: translateY(-50%);width: 4px;height: 4px;border-radius: 100%;background: #fff;right: 6px}

.headCarNumberBox{display: flex;align-items: center;gap: 8px}
.headGradeBox{border: 2px solid #303036;background: #464650;border-radius: 6px;display: inline-flex;align-items: center;justify-content: center;gap: 4px;padding: 3px 8px}
.headGradeBox>img{min-width: 12px;width: 12px}
.headGrade{font-size: 13px;font-weight: 700;color: #fff}

.maintitleBox{padding: 20px 20px 0}
.mainTitle{font-size: 24px;font-weight: 700}
.mainTitle span{text-decoration: underline}

.waitingTimeBanner{width: 100%;border-radius: 14px;max-width: calc(100% - 40px);margin: 36px auto 0;display: block;overflow: hidden;}
.waitingTimeBanner_textBox{background: #3183FF;display: flex;justify-content: space-between;align-items: center;text-align: left;gap: 20px;padding: 25px 20px 20px;}
.waitingTimeBanner.notTime .waitingTimeBanner_textBox{background: #FF313D}
.waitingTimeBanner_titleInfo{width: calc(100% - 20px - 32px);display: flex;align-items: flex-end;justify-content: flex-start;gap: 12px;}
.waitingTimeBanner_titleBox{width: calc(100% - 50px - 12px)}
.waitingTimeBanner_subTitle{font-size: 14px;font-weight: 600;color: rgba(255, 255, 255, 0.9)}
.waitingTimeBanner_title{margin: 0;font-size: 22px;font-weight: 700;color: #fff}
.waitingTimeBanner_btn{width: 32px;min-width: 32px;}
.waitingTimeBanner_subText{font-size: 14px;margin-top: -16px}
.waitingTimeBanner_imgBox{position: relative;min-width: 50px;}
.waitingTimeBanner_imgBox .carImg{max-width: 50px}
.loading_iconBox{position: absolute;right: 12px;top: -13px;}
.loading_iconBox .loading_box{max-width: 16px;filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.10));}
.loading_icon{width: 10px;position: absolute;left: 50%;top: 3px;transform: translateX(-50%);animation: spinBanner 1s infinite;}

.waitingTimeBanner_tableBox{background: #303036;padding: 12px 0;}
.waitingTimeBanner_table th{width: 43%;min-width: 43%;padding: 0 5px;white-space: nowrap;font-weight: 600;font-size: 12px;color: rgba(255, 255, 255, 0.6);text-align: center;}
.waitingTimeBanner_table th:first-child{width: 14%;min-width: 14%;padding: 0 10px 0 20px;}
.waitingTimeBanner_table th:last-child{padding-right: 20px}
.waitingTimeBanner_table .breakTimeBox{border-top: 1px dashed rgba(255, 255, 255, 0.1);}
.waitingTimeBanner_table td{width: 43%;min-width: 43%;font-weight: 700;font-size: 14px;padding: 12px 5px 0;text-align: center;}
.waitingTimeBanner_table td:first-child{width: 14%;min-width: 14%;padding: 12px 10px 0 20px;white-space: nowrap;font-weight: 600;font-size: 12px;color: rgba(255, 255, 255, 0.6);}
.waitingTimeBanner_table td.lastTd{padding-right: 20px;padding-bottom: 12px}
.waitingTimeBanner_table .lastMainTr td{padding-bottom: 12px}
.waitingTimeBanner_table td.lastTd[rowSpan="2"]{padding-bottom: 12px}

.mainMenuBox{padding: 40px 45px 0;display: flex;align-items: flex-start;justify-content: center;gap: 15px 35px;flex-wrap: wrap}
.mainMenuItem_text{font-size: 13px;font-weight: 700;color: #fff;margin: 10px 0 0;opacity: 0.9}
.mainMenuItem_imgBox img{min-width: 66px;width: 66px}
.mainVideoBox{margin: 40px auto 0;max-width: calc(100% - 40px);width: 100%;border-radius: 14px;overflow: hidden}
.mainVideoBox video{display: block}
.mainSection{padding: 60px 20px 0}
.mainSection:last-child{padding-bottom: 60px}
.mainSubTitleBox{margin: 0 0 12px}
.mainSubTitle{font-size: 22px;font-weight: 700;color: #fff}
.mainGuideBox{margin-top: 28px}

.mainGuideItem{display: flex;align-items: flex-start;justify-content: flex-start;gap: 15px;padding: 0 0 45px;position: relative}
.mainGuideItem:last-child{padding: 0}
.mainGuideItem::before{position: absolute;content: "";width: 1px;height: 100%;background: #73F7CA;left: 10px;top: 0}
.mainGuideItem:last-child::before{display: none}
.mainGuideItem_number{line-height: 1;min-width: 21px;width: 21px;height: 21px;display: flex;align-items: center;justify-content: center;text-align: center;color: #19191B;font-size: 14px;font-weight: 700;background: #73F7CA;border-radius: 100%;position: relative;z-index: 1}
.mainGuideItem_title{color: #fff;font-size: 16px;font-weight: 600}
.mainGuideItem_text{font-size: 14px;color: rgba(255, 255, 255, 0.6);margin: 2px 0 0}
.mainTicketBanner{margin: 28px 0 0;background: #222225;border-radius: 14px;padding: 20px 18px}
.mainTicketBanner_info{display: flex;align-items: center;justify-content: flex-start;gap: 14px;margin: 0 0 16px}
.mainTicketBanner_info img{min-width: 60px;max-width: 60px}
.mainTicketBanner_text{font-size: 14px;color: rgba(255, 255, 255, 0.8)}
.mainBoardSlideBox{margin: 13px 0 0}

.mainNoticeItem .noticeItem_titleBox{width: calc(100% - 23px)}
.mainNoticeItem.noticeItem:last-child{border: 0}
.mainNoticeItem.noticeItem{padding-left: 0;padding-right: 0}

.swiper-pagination{padding: 10px 0 0;width: 100%;display: flex;align-items: center;justify-content: center;gap: 6px}
.swiper-pagination-bullet{background: #464650;width: 6px;height: 6px;min-width: 6px;border-radius: 100%}
.swiper-pagination-bullet-active{background: #57B07B}

.main_eventItem{margin: 20px 0;border-radius: 14px;overflow: hidden;width: 100%;padding: 0 0 38%;position: relative}
.main_eventItem_title{font-size: 18px;font-weight: 800;color: #fff;position: absolute;top: 50%;transform: translateY(-50%);left: 0;padding: 20px;text-align: left}

.degreeTotalInfoBox{padding: 0 20px 20px}
.degreeTotalInfoItem{border-bottom: 1px solid #424243;padding: 16px 0;display: flex;align-items: center;justify-content: space-between;gap: 10px;opacity: 0.4;width:100%}
.degreeTotalInfoItem.active{opacity: 1}
.degreeTotalInfoItem_nameBox{display: flex;align-items: center;gap: 10px}
.degreeTotalInfoItem_nameBox>img{min-width: 44px}
.degreeTotalInfoItem_name{color: #fff;font-size: 16px;font-weight: 700}
.captionItem{border: 1px solid #73F7CA;border-radius: 6px;padding: 3px 8px;font-weight: 700;color: #73F7CA;font-size: 14px}

.waitingTimeItem{display: flex;align-items: center;padding: 15px 20px}
.waitingTimeItem_name{min-width: 70px;font-size: 14px;font-weight: 500;color: #fff}
.waitingTimeItem_graphBox{height: 6px;width: calc(100% - 140px);background: #464650;border-radius: 30px}
.waitingTimeItem_graph{border-radius: 30px;height: 100%;width: 0;transition: width 0.2s;max-width: 100%}
.waitingTimeItem_time{min-width: 70px;font-size: 14px;font-weight: 600;color: #fff;text-align: right}
.waitingTimeItem.rColor .waitingTimeItem_graph{background: #F04C41}
.waitingTimeItem.oColor .waitingTimeItem_graph{background: #FFC453}
.waitingTimeItem.bColor .waitingTimeItem_graph{background: #3183FF}
.waitingTimeItem.rColor .waitingTimeItem_time{color:#F04C41}
.waitingTimeItem.oColor .waitingTimeItem_time{color:#FFC453}
.waitingTimeItem.bColor .waitingTimeItem_time{color:#3183FF}
.waitingTimeItem.nowTime .waitingTimeItem_graph{background: #73F7CA;box-shadow: 0 0 6px 0 #73F7CA}
.waitingTimeItem.nowTime .waitingTimeItem_name{color: #73F7CA}
.waitingTimeItem.nowTime .waitingTimeItem_time{color: #73F7CA}
.waitingTimeItem.nowTime{background: rgba(115, 247, 202, 0.1)}
.waitingTimeBox{width:100vw;transform: translate(-20px)}

.cafeBox{padding: 20px 20px 30px;gap: 30px 12px;display: flex;flex-wrap: wrap;align-items: flex-start;justify-content: space-between}
.cafeItem{width: calc(50% - 6px)}
.cafeItem_imgBox{position: relative}
.cafeItem_img{width: 100%;padding: 0 0 100%;border-radius: 14px;background-color: #303036}
.add_cafeItem{position: absolute;right: 10px;bottom: 10px}
.cafeItem.active .add_cafeItem{display: none}
.cafeItem.active .add_cafeItemCountBox{display: block}
.add_cafeItemCountBox{width: calc(100% - 20px);position: absolute;right: 10px;bottom: 10px;display: none}
.countBox{display: flex;align-items: center;justify-content: space-between;gap: 5px;width: 100%;border-radius: 30px;background: #73F7CA;overflow: hidden}
.countInput{width: calc(100% - 64px - 10px);text-align: center;background: transparent !important;border: 0 !important;font-weight: 600;font-size: 18px;color: #000 !important}
.cafeItem_info{margin: 10px 0 0}
.cafeItem_name{font-size: 15px;font-weight: 500;color: #fff}

.mainPopupTitleBox{display: flex;width: 100%;align-items: center;justify-content: space-between;padding: 0 20px}
.mainPopupTitle{display: flex;align-items: center;justify-content: flex-start;gap: 8px;font-size: 16px;font-weight: 600;color: #0B0B0C}
.mainPopupTitle_caption{text-align: right;color: #0B0B0C;font-size: 16px}

.mainPopupTitleBox.bColor{padding: 13.5px 0}
.mainPopupTitleBox.bColor .mainPopupTitle{color: rgba(255, 255, 255, 0.8)}
.mainPopupTitleBox.bColor .mainPopupTitle_caption{color: #73F7CA}
.mainPopupSubContents{padding: 10px 20px 20px;text-align: center;width:100%}
.mainPopupSubContents>img{margin: 0 auto 10px}
.mainPopupSubContents_title{font-size: 24px;font-weight: 700}
.mainPopupSubContents_text{color: rgba(255, 255, 255, 0.8);font-size: 16px;margin: 8px 0 0}
.mainPopupSubContents_text>span{font-size: 14px;margin: 6px 0 0;display: block}

.mainPopupContentsBox{height: 100%;display: flex;flex-direction: column;justify-content: space-between;align-items: center}

.mainPopupBottomBox{width: 100%;padding: 10px 20px 20px}
.mainPopupProductBox{margin: 10px 0 0;background: #464650;border-radius: 14px;display: flex;align-items: center;gap: 16px;padding: 16px 20px}
.mainPopupProduct_iconBox{position: relative}
.mainPopupProduct_iconCaption{height: 14px;position: absolute;right: -4px;top: -2px;background: #73F7CA;border-radius: 31px;color: #222225;font-size: 12px;font-weight: 800;text-align: center;padding: 0 4px}
.mainPopupProduct_info{font-size: 14px}
.mainPopupProduct_name{font-size: 16px;font-weight: 700}
.mainPopupProductBox + .pageBtn{margin-top: 20px}

.mainPopupContentsTitleBox{width: 100%;padding: 10px 20px;text-align: center}
.mainPopupContentsTitle{font-size: 16px;color: rgba(255, 255, 255, 0.9)}

.mainPopupReProductBox{background: #464650;border-radius: 14px;overflow: hidden}
.mainPopupReProductBox .mainPopupProductBox{margin: 0}

.mainPopup_textBtn{padding: 20px 20px 0;text-align: center}

.filpCalender{perspective: 600px;position: relative;height: 30px;line-height: 30px;background-color: #2b75e5;display: inline-block;width: 22px;transform: translateY(8px);margin: 0 4px 0 3px;border-radius: 3px;overflow: hidden}
.filpCalender > div{height: 50%;width: 100%;overflow: hidden;position: relative;line-height: 30px}
.filpCalender > div span{left: 0;position: absolute;text-align: center;width: 100%;height: 30px;display: flex;align-items: center;justify-content: center;line-height: 1}
.topItemCover{background-color: #2b75e5;top: 0;-webkit-transform-origin: center bottom;-ms-transform-origin: center bottom;transform-origin: center bottom;animation: 2s linear 0.1s infinite normal backwards running filpUp;z-index: 10;position: absolute !important;left: 0}
.bottomItemCover{background-color: #2b75e5;top: 50%;box-sizing: border-box;-webkit-transform-origin: center top;-ms-transform-origin: center top;transform-origin: center top;-webkit-transform: rotateX(90deg);-ms-transform: rotateX(90deg);transform: rotateX(90deg);animation: 2s linear 0.6s infinite normal backwards running filpDown;z-index: 2;position: absolute !important;left: 0}
.bottomItemCover::before,.bottomItem::before{content: "";position: absolute;left: 0;top: 0;width: 100%;height: 1px;background: rgba(255, 255, 255, 0.2)}
.topItem span{top: 0}
.topItemCover span{top: 0}
.bottomItem span{bottom: 0}
.bottomItemCover span{bottom: 0}

/*board*/
.noticeItem{display: flex;align-items: center;justify-content: space-between;gap: 15px;width: 100%;text-align: left;padding: 16px 20px;border-bottom: 1px solid #303036}
.noticeItem_titleBox{width: 100%}
.noticeItem_title{font-size: 15px;font-weight: 500;color: #fff;width: 100%;text-overflow: ellipsis;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical}
.noticeItem_date{font-size: 13px;margin: 8px 0 0;color: #6E6E77}
.noticeItem>img{min-width: 8px}

.mainPopupTitle .notice_badge{background: #57E2B3;border-radius: 6px;display: inline-flex;align-items: center;justify-content: center;gap: 10px;padding: 6px;width: 35px;height: 25px;font-size: 13px;color: #000;font-weight: 800}
.notice_titleBox{height: 23px;overflow: hidden;width: 100%}
.notice_title{font-size: 15px;font-weight: 700;color: #0B0B0C}
.notice_title + .notice_title{margin: 40px 0 0}

.detailTitleBox{padding: 20px;border-bottom: 1px solid #303036}
.detailTitle{font-weight: 700}
.detailDate{font-size: 13px;color: #6E6E77;margin: 4px 0 0}
.detailContents{padding: 20px 20px 0}
.detailTitleBox.bgImgTitleBox{padding-top: 85px}

.eventListBox{padding: 0 20px}
.eventItem{display: block;width: 100%}
.eventItem + .eventItem{margin: 16px 0 0}
.eventItem_conetents{border-radius: 14px;padding: 20px 20px 44px;text-align: left;background-color: #2d2d2e;min-height: 170px}
.eventItemType{border-radius: 4px;display: inline-block;padding: 0 5px;line-height: 20px;background: #222225;font-weight: 600;font-size: 13px;margin: 0 0 8px}
.eventItemType.endEvent{background: #6E6E77;color: rgba(255, 255, 255, 0.6)}
.eventItem_title{font-weight: 700;color: #fff;font-size: 20px}
.eventItem_text{color: rgba(255, 255, 255, 0.6);font-size: 14px;margin: 3px 0 0}
.eventItem_date{font-size: 14px;font-weight: 500;color: rgba(255, 255, 255, 0.6);text-align: left;margin: 4px 0 0}

.mainPopupBoardSlideBox{border-top-left-radius: 20px;border-top-right-radius: 20px;overflow: hidden}
.mainPopup_eventItem{width: 100%;padding: 0 0 44%;position: relative;display: block}
.mainPopup_eventItem_title{font-size: 20px;font-weight: 700;color: #fff;text-align: left;position: absolute;padding: 20px 20px 0;width: 100%;height: calc(100% - 25px - 20px);display: flex;align-items: center;justify-content: flex-start}
.mainPopupBoardSlideBox .swiper-pagination.swiper-pagination-fraction{color: #fff;font-weight: 600;font-size: 13px;position: absolute;z-index: 10;left: 20px;bottom: 10px;width: auto;padding: 3px 16px;min-height: 25px;border-radius: 100px;background: rgba(0, 0, 0, 0.2);gap: 4px}

/*wash*/
.washListBox{padding: 20px 20px 50px}
.washListItem{background: #303036;border-radius: 14px;overflow: hidden;padding: 20px 0 0}
.washListItem + .washListItem{margin: 20px 0 0}
.washListItem.bonus {border: 1px solid #73F7CA}
.washListItem.detailItem{padding-bottom: 20px}
.washListItem_nameBox{padding: 0 20px}
.washListItem_name{font-weight: 700;font-size: 18px;color: #fff;display: flex;align-items: center;gap: 6px}
.washListItem_badgeBox{display: flex;gap: 4px;margin-bottom: 8px}
.washListItem_badge{color: #FFF;font-size: 11px;width:fit-content;align-items: center;font-weight: 700;padding: 5px;border-radius: 5px;line-height: 1}
.washTypeCaption{font-size: 11px;font-weight: 700;border: 1px solid #fff;border-radius: 4px;padding: 2px 6px}
.washTypeCaption.gColor{color: #44D4A3;border-color: #44D4A3}
.washTypeCaption.bColor{color: #3183FF;border-color: #3183FF}
.washListItem_caption{margin: 4px 0 0;font-size: 13px;color: #fff;white-space: pre-wrap}
.washListItem_name span{display: block;border-radius: 4px;font-size: 11px;font-weight: 700;padding: 4px 8px;line-height: 1;white-space: nowrap;min-width: 37px;border: 1px solid transparent}
.washListItem_name span.bColor{color: #3183FF;border-color: #3183FF}
.washListItem_name span.gColor{color: #44D4A3;border-color: #44D4A3}
.washListItem_optionBox{margin: 16px 0 0;display: flex;flex-wrap: wrap;gap: 16px 20px;padding: 0 20px}
.washListItem_optionBox:last-child{margin: 16px 0 20px}
.washListOptionItem{width: calc(50% - 10px);display: flex;align-items: center;justify-content: flex-start;gap: 10px;font-size: 14px;font-weight: 500;color: #fff}
.washListOptionItem_img{min-width: 30px;max-width: 30px;height: 30px;background-color: #222225;border-radius: 6px;overflow: hidden;display: flex;align-items: center;justify-content: center}
.washListOptionItem_img img{width:60%}
.washListItem_btnBox{display: flex;justify-content: space-between;margin: 20px 0 0}
.washListBtn{display: flex;align-items: center;justify-content: space-between;gap: 8px;width: 100%;background: #73F7CA;padding: 19px 16px;font-weight: 600;color: #222225;font-size: 16px;text-align: left}
.washListBtn.monthBtn{background: #44D4A3}
.washListBtn:first-child:nth-last-child(2),.washListBtn:first-child:nth-last-child(2) ~ .washListBtn{width: 50%}
.washListBtn img{min-width: 13px}
.washListBtn:disabled{background: #6E6E77;color: #464650}
.washListBtn.washListUseBtn{background: #6E6E77;color: #464650}
.washListBtn.washListUseBtn img{opacity: 0.6}
.washListBtn.washListTextBtn{cursor: auto}
.washListItem_packageBox{display: flex;flex-direction: column;align-items: flex-start;gap: 6px;margin-top: 9px;margin-bottom: 20px}
.washListItem_packageBoxItemBonus{display: flex;padding: 3px 5px;line-height: 1;align-items: flex-start;gap: 3px;border-radius: 4.8px;border: 0.9px solid #73F7CA;background: rgba(115, 247, 202, 0.60);color: #FFF;font-size: 11px;font-style: normal;font-weight: 700}
.washListItem_packageBoxItem{display: flex;padding: 9px 10px;align-items: center;gap: 10px;border-radius: 10px;background: #222225;width: 100%}
.washListItem_packageBoxItem.disabled{background: #464650}
.washListItem_packageBoxItemName{display: flex;flex-direction: column;justify-content: center;flex: 1 0 0;align-self: stretch;font-size: 15px;font-weight: 700}
.washListItem_packageBoxItemName.bonus{color: #73F7CA}
.washListItem_packageBoxItemAmount{color: #73F7CA;text-align: right;font-size: 14px;font-style: normal;font-weight: 700}
.washListItem_packageBoxItemStatus{color: #73F7CA;text-align: right;font-size: 15px;font-style: normal;font-weight: 400}
.washListItem_packageBoxItem.disabled > .washListItem_packageBoxItemName{color: #6E6E77}
.washListItem_packageBoxItem.disabled > .washListItem_packageBoxItemStatus{color: #6E6E77}
.washListItem_packageBoxItem.disabled > .washListItem_packageBoxItemBonus{color: #6E6E77;background: rgba(110, 110, 119, 0.60);border: 0.9px solid #6E6E77}
.washListItem_packagePrice{display: flex;justify-content: space-between;align-items: center;flex: 1 0 0}
.washListItem_price{display: flex;justify-content: center;align-items: flex-end;gap: 6px}
.washListItem_percent{display: flex;justify-content: flex-end;align-items: center;gap: 10px;font-weight: 400}
.washListItem_originalPrice{font-weight: 400;font-size: 14px;text-decoration: line-through;opacity: 0.3}
.washListItem_subOptionBox{margin: 16px auto 0;border-radius: 8px;overflow: hidden;width: calc(100% - 40px);background: rgba(11, 11, 12, 0.8)}
.washListItem_subOptionBox:last-child{margin-bottom: 20px}
.washListItem_subOptionTitle{font-size: 15px;font-weight: 500;padding: 10px 34px 10px 12px;width: 100%;text-align: left;position: relative}
.washListItem_subOptionTitle::before{content: "";position: absolute;top: 50%;right: 12px;transform: translateY(-50%);background: url(/assets/images/basic/sel.svg) no-repeat center;background-size: 100%;width: 11px;height: 6px;transition: transform 0.2s}
.washListItem_subOptionBox.active .washListItem_subOptionTitle::before{transform: translateY(-50%) rotate(180deg)}
.washListItem_subOption{display: flex;flex-wrap: wrap;gap: 16px 20px;padding: 12px;display: none}
.washListItem_packageGuideText{color: rgba(255, 255, 255, 0.80);font-size: 14px;font-weight: 400;position: relative;padding-left: 18px;line-height: 180%}
.washListItem_packageGuideText::before{content: "";position: absolute;left: 4px;top: 10px;width: 3px;height: 3px;border-radius: 100%;background: rgba(255, 255, 255, 0.80)}
.washListItem_expirationDay{display: flex;flex-direction: column;align-items: center;opacity: 0.5;font-size: 13px;font-weight: 400;margin-top:18px}
.washListItem_expirationDay + .washListItem_expirationDay{margin-top: 8px}
.washListItem.bottomMargin{margin-bottom: 20px}

.guideTextBox + .chk_item{margin: 15px 0 0;padding: 15px 0 0;border-top: 1px solid #303036}
.chk_item + .guideTextBox{margin: 14px 0 0}
.guideTextBox + .guideTextBox{margin: 14px 0 0}
.guideText{color: rgba(255, 255, 255, 0.80);font-size: 14px;font-weight: 400;position: relative;padding-left: 18px;line-height: 180%}
.guideText::before{content: "";position: absolute;left: 4px;top: 10px;width: 3px;height: 3px;border-radius: 100%;background: rgba(255, 255, 255, 0.80)}
.guideTextBox_title{font-size: 15px;font-weight: 700;margin: 0 0 6px}

.washListItem_price_saleBox{display: flex;gap: 0 6px;align-items: flex-end;flex-wrap: wrap}
.washListItem_price_sale{display: flex;flex-direction: row-reverse;justify-content: flex-end;align-items: center;gap: 0 6px;flex-wrap: wrap}
.washListItem_price_sale b{font-weight: 600}

.itemSectionTitleBox{margin: 0 0 10px}
.itemSectionTitleBox + .inputItemBox{margin-top: 0}
.itemSectionTitle{font-size: 16px;font-weight: 700;color: #fff}
.paymentBox{display: flex;flex-wrap: wrap;gap: 15px 10px;align-items: flex-start;justify-content: flex-start}
.paymentItem{width: 80px;min-width: 80px;display: flex;align-items: center;justify-content: space-between;gap: 6px;flex-direction: column}
.paymentItem:disabled .paymentItem_iconBox img{opacity: 0.15}
.paymentItem:disabled .paymentItem_name{color: #464650}
.paymentItem_iconBox{border: 2px solid transparent;background: #303036;border-radius: 14px;width:100%;width: 72px;height: 72px;display: flex;align-items: center;justify-content: center}
.paymentItem.active .paymentItem_iconBox{border-color: #fff}
.paymentItem_name{color: rgba(255, 255, 255, 0.8);font-size: 14px}
.paymentItem.active .paymentItem_name{color: #fff;font-weight: 700}

.addrBox{width: 100%;height: 220px;border-radius: 18px;overflow: hidden;margin: 16px 0 0}

.nameTextItem{display: flex;align-items: flex-start;justify-content: flex-start}
.nameTextItem + .nameTextItem{margin: 8px 0 0}
.nameTextItem_name{min-width: 100px;width: 100px;padding-right: 15px;font-size: 14px;color: rgba(255, 255, 255, 0.8)}
.nameTextItem_text{font-size: 14px;width: calc(100% - 100px);color: rgba(255, 255, 255, 0.8)}

.qrBox{width: 100%;max-width: 280px;margin: 30px auto;background: #fff;border-radius: 14px}
.qrBox img{width: 100%}

/*mypage*/
.cardItemBox{min-height: calc(100vh - 55px - 54px);display: flex;align-items: center;justify-content: center;padding: 40px 20px}
.cardItem{border-radius: 15px;width: 280px;height: 190px;background: #30BE8E;padding: 20px;display: flex;flex-direction: column;align-items: flex-start;justify-content: space-between}
.cardItem.notCard{background: #222225}
.cardItem.bc{background: #FF6666}
.cardItem.hana{background: #00AAAA}
.cardItem.hyundai{background: #3399FF}
.cardItem.kb{background: #F7CE46}
.cardItem.lotte{background: #DD4444}
.cardItem.nh{background: #66BB44}
.cardItem.samsung{background: #0066AA}
.cardItem.shinhan{background: #004488}

.cardItem_head{display: flex;align-items: flex-start;gap: 10px;justify-content: space-between;width: 100%}
.cardName{color: #fff;font-size: 18px;font-weight: 700}
.cardSimImg{margin: 20px 0 0 20px}
.cardNumber{width: 100%;text-align: right;font-size: 18px;font-weight: 700;color: #fff}
.cardDel{min-width: 13px;margin: 4px 0 0}
.cardItem_topInfo{width:100%}

.itemSectionTitleBox + .cardItem.min{margin: 40px auto}
.cardItem.min{width: 144px;height: 96px;margin: 0 auto;padding: 10px;border-radius: 10px}
.cardItem.min .cardNameImg{height: 16px}
.cardItem.min .cardSimImg{margin: 11px 0 0 6px;width: 18px}
.cardItem.min .cardNumber{font-size: 12px}
.cardItem.min .cardName{font-size: 12px}

.settingItem{cursor: auto;width: 100%;display: flex;align-items: center;justify-content: space-between;gap: 15px;padding: 20px;text-align: left}
.settingItem + .settingItem{border-top: 1px solid #303036}
.settingItemName{font-weight: 700;font-size: 15px}
.settingItemIcon{color: rgba(255, 255, 255, 0.6);font-size: 14px;margin: 6px 0 0}

.couponAddBox{border-bottom: 1px solid #303036;padding: 20px}
.couponAddBox .inputItemBox{margin: 0 0 12px}
.couponItemBox{padding: 20px 20px 50px}
.couponItemBox.select{padding: 20px 0 0 0}
 
.couponItem{background: #222225;border-radius: 15px;padding: 16px;position: relative;display: flex}
.couponItem_disabled{opacity: 0.5}
.couponItem .checkbox{display: flex}
.couponItem + .couponItem{margin: 12px 0 0}
.couponItem::before{content: "";position: absolute;right: 0;top: 50%;transform: translate(50%,-50%);background: #19191B;width: 20px;height: 20px;border-radius: 100%}
.coupon_cost{font-size: 20px;font-weight: 700;margin: 0 0 4px}
.coupon_name{font-size: 14px;font-weight: 600;margin: 0 0 20px}
.coupon_status{font-size: 13px;font-weight: 500;margin: 0 0 4px}
.coupon_status.end{color: #6E6E77}
.coupon_date{color: #6E6E77;font-size: 13px;font-weight: 500}

.myPointInfo{border-bottom: 6px solid #303036;padding: 0 0 15px}
.myPointBox{padding: 40px 20px}
.myPoint{display: flex;align-items: center;justify-content: center;font-size: 24px;font-weight: 700;gap: 6px}
.linkItemBox{display: flex;align-items: center;justify-content: space-between}
.linkItem{text-align: center;padding: 0 10px;display: flex;align-items: center;justify-content: center;gap: 8px;position: relative}
.linkItem img{min-width: 17px}
.linkItem_name{color: rgba(255, 255, 255, 0.9);font-size: 14px}
.linkItem:first-child:nth-last-child(2),.linkItem:first-child:nth-last-child(2) ~ .linkItem{width: 50%}
.linkItem:first-child:nth-last-child(3),.linkItem:first-child:nth-last-child(3) ~ .linkItem{width: 33.33%}
.linkItem:first-child:nth-last-child(4),.linkItem:first-child:nth-last-child(4) ~ .linkItem{width: 25%}
.linkItem + .linkItem::before{content: "";min-width: 1px;height: 16px;background: #303036;position: absolute;left: 0;top:0.5px}
.pointItem{border-bottom: 1px solid #303036;display: flex;align-items: flex-start;justify-content: space-between;padding: 20px;gap: 10px}
.pointItem_type{font-weight: 600;font-size: 14px}
.pointItem_date{font-size: 13px;font-weight: 500;color: #6E6E77;margin: 6px 0 0}
.pointItem_pointBox{text-align: right}
.pointItem_point{font-weight: 500;font-size: 15px;color: rgba(255, 255, 255, 0.9)}
.pointItem_total{font-size: 13px;font-weight: 500;color: #6E6E77;margin: 6px 0 0}

.pointAddBtnBox{margin: 12px 0 0;border-radius: 10px;border: 1px solid #303036;overflow: hidden;display: flex;align-items: center;justify-content: space-between}
.pointAddBtn{color: rgba(255, 255, 255, 0.9);padding: 10px 5px;font-size: 13px;font-weight: 500;text-align: center}
.pointAddBtn + .pointAddBtn{border-left: 1px solid #303036}
.pointAddBtn:first-child:nth-last-child(2),.pointAddBtn:first-child:nth-last-child(2) ~ .pointAddBtn{width: 50%}
.pointAddBtn:first-child:nth-last-child(3),.pointAddBtn:first-child:nth-last-child(3) ~ .pointAddBtn{width: 33.33%}
.pointAddBtn:first-child:nth-last-child(4),.pointAddBtn:first-child:nth-last-child(4) ~ .pointAddBtn{width: 25%}
.pointAddBtn:first-child:nth-last-child(5),.pointAddBtn:first-child:nth-last-child(5) ~ .pointAddBtn{width: 20%}

.alarmItem{padding: 18px 20px;background: #19191B;display: flex;align-items: flex-start;justify-content: space-between;gap: 10px;border-top: 1px solid #303036}
.alarmItem img{min-width: 22px;transform: translateY(-3px)}
.alarmInfo{width: calc(100% - 32px)}
.alarmInfo_title{font-size: 16px;font-weight: 500;width:100%}
.alarmInfo_text{color: rgba(255, 255, 255, 0.8);font-size: 14px;margin: 2px 0 0;width:100%;white-space: pre-wrap}
.alarmInfo_date{color: #6E6E77;font-size: 12px;margin: 12px 0 0;width:100%}

.myPageTitleBox{font-size: 22px;font-weight: 700;line-height: 30px;padding: 0 0 10px}
.MyPageTitleBtn{transform: translateY(4px);margin: 0 0 0 3px}
.myPageMenuItem{display: flex;width: 100%;gap: 15px;align-items: center;justify-content: space-between;padding: 10px 20px}
.myPageMenuItem_name{display: flex;align-items: center;gap: 12px;font-size: 16px;font-weight: 700}
.myPageMenuItem_name img{min-width: 30px}
.myPageMenuItem_subInfo{display: flex;align-items: center;justify-content: flex-end;text-align: right;gap: 12px}
.myPageMenuItem_subText{font-size: 15px;font-weight: 500;color: #3183FF;line-height: 24px}
.myPageMenuItem_subInfo>img{min-width: 6px}

.textBtnItemBox.withdrawalBox{padding: 10px 0}
.textBtnItemBox.withdrawalBox .textBtn_contents{font-size: 15px;color: rgba(255, 255, 255, 0.6)}

/*timeSelBox*/
.timeSelBox{display: flex;flex-wrap: wrap;gap: 10px 6px}
.timeSelItem{width: calc(20% - 4.8px);background: #303036;border-radius: 8px;font-size: 14px;font-weight: 600;color: #fff;padding: 9px 5px}
.timeSelItem:disabled{color: #6E6E77;cursor: auto}
.timeSelItem.active{background: #73F7CA;color: #0B0B0C;font-weight: 700}

/*detailing*/
.detailItem{background: #303036;border-radius: 14px;overflow: hidden}
.detailItemBox .detailItem + .detailItem{margin: 20px 0 0}
.detailItem_nameBox{padding: 20px}
.detailItem_name{font-size: 17px;font-weight: 700}
.detailItem_caption{margin: 10px 0 0;font-size: 14px;font-weight: 400;white-space: pre-wrap}
.detailItem_caption.bTextType{font-size: 20px;font-weight: 700}
.detailItem_textBtn{text-align: center;margin: 20px 0 0}
.detailItemBtn{display: flex;align-items: center;justify-content: space-between;gap: 10px;width: 100%;font-size: 16px;font-weight: 600;color: #222225;background: #73F7CA;padding: 16px 16px}
.detailItemBox{padding: 20px 20px 50px}
.detailItemBox.itemSwiperSection{padding-left: 0;padding-right: 0;padding-bottom: 20px}
.detailItemBox.itemSwiperSection .swiper{padding: 0 20px}
.detailItem_date{margin: 10px 0 0}
.detailItem_date,.detailItem_date span{font-weight: 700;color: #888892;font-size: 14px}

.detailItemBox.itemSwiperSection .itemSectionTitleBox{padding: 0 20px}
.itemSection + .detailItemBox{border-top: 6px solid #303036}

.detailItem.isUseType .detailItemBtn{color: #464650;background: #6E6E77}
.detailItem.isUseType .detailItemBtn img{opacity: 0.6}

.detailingTextBox{background: rgba(11, 11, 12, 0.8);max-width: 100%;margin: 10px auto 0;border-radius: 10px;padding: 8px 12px}
.detailingTextItem + .detailingTextItem{margin: 10px 0 0}
.detailingTextItem_name{color: #73F7CA;font-size: 13px;font-weight: 700}
.detailingTextItem_value{font-size: 13px;font-weight: 500;white-space: pre-wrap}

/*editerBox*/
.editerBox *{font-weight: revert;color: revert;font-family: revert;font-size: revert;line-height: revert;white-space: pre-wrap}
.editerBox img{max-width: 100%}

/*report*/
.reportAboutTextBox + .reportAboutTextBox{margin: 50px 0 0}
.reportAbout_img{width: 100%;margin: 10px 0 0}
.reportAbout_title{padding: 0 20px;font-size: 16px;font-weight: 700;color: #FFFFFF}
.reportAbout_text{color: rgba(255, 255, 255, 0.8);font-size: 14px;padding: 0 20px}
.reportAboutSection + .reportAboutSection{margin: 50px 0 0}
.reportBtnBox{padding: 40px 20px}
.btnTextType{font-size: 13px;text-align: center;color: rgba(255, 255, 255, 0.6);margin: 0 0 8px}
.reportAbout_titleCaption{font-size: 12px;font-weight: 700;padding: 0 20px;margin: 0 0 4px}
.swiper + .reportAboutTextBox{margin: 30px 0 0}
.reportAboutTextBox.none{display: none}
.reportAboutTextBox.active{display: block}
.reportAbout_slideBox .swiper{padding: 20px 90px 0;overflow: visible}
.reportAbout_slideBox .swiper::before{content: "";position: absolute;bottom: 13px;left: 50%;transform: translateX(-50%);width: calc(100% - 180px);box-shadow: 0 0 30px 10px rgba(115, 247, 202, 0.15)}
.reportAbout_slideImg{width: 100%}
.reportAbout_slideImg{max-width: 48.61vw}
.reportAbout_slideImg img{width: 100%;margin: 0 auto}
.reportAbout_slideBox .swiper-slide{opacity: 0.5;transition: opacity 0.2s}
.reportAbout_slideBox .swiper-slide-active{opacity: 1}

.reportFuncBox{padding: 0 20px 20px}
.reportFuncItem{margin: 10px 0 0;border-radius: 8px;background: #222225;padding: 16px}
.reportFuncItem_titleBox{display: flex;align-items: flex-start;justify-content: flex-start;gap: 10px}
.reportFuncItem_titleBox>img{min-width: 30px;max-width: 30px}
.reportFuncItem_title{width: calc(100% - 40px);margin: 5px 0 0;font-size: 15px;font-weight: 700}
.reportFuncItem_text{color: rgba(255, 255, 255, 0.8);margin: 11px 0 0;font-size: 13px}

.reportListItem{display: flex;align-items: flex-start;justify-content: space-between;width: 100%;text-align: left;border-bottom: 1px solid #303036;padding: 17px 20px}
.reportListItem_carNumber{font-size: 15px}
.reportListItem_title{font-size: 15px;color: rgba(255, 255, 255, 0.9)}
.reportListItem_date{font-size: 13px;color: #6E6E77;margin: 6px 0 0}
.reportListItem_score{background: rgba(115, 247, 202, 0.1);border-radius: 12px;padding: 10px 12px;color: #44D4A3;font-size: 15px;font-weight: 700;display: flex;align-items: center;justify-content: center;text-align: center}

.reportInfo_title{font-size: 16px;font-weight: 500;color: rgba(255, 255, 255, 0.9)}
.reportInfo_table{margin: 20px 0 0;border-radius: 12px;background: #222225;padding: 0 15px}
.reportInfoTableItem{display: flex;align-items: center;justify-content: space-between;gap: 15px;padding: 12px 0}
.reportInfoTableItem + .reportInfoTableItem{border-top: 1px solid #303036}
.reportInfoTable_name{font-size: 16px;font-weight: 700}
.reportInfoTable_textBox{display: flex;align-items: flex-end;justify-content: flex-end;gap: 6px}
.reportInfoTable_textCaption{font-size: 13px;font-weight: 500;color: rgba(255, 255, 255, 0.8)}
.reportInfoTable_text{font-size: 16px}
.reportInfo_text{color: rgba(255, 255, 255, 0.8);font-size: 14px;margin: 20px 0 0}
.reportInfo_subTextBox{margin: 30px 0 0}
.reportInfo_subText{font-size: 13px;color: #6E6E77;position: relative;padding-left: 11px}
.reportInfo_subText + .reportInfo_subText{margin: 10px 0 0}
.reportInfo_subText::before{content: "";position: absolute;left: 4px;top: 6px;width: 3px;height: 3px;border-radius: 100%;background: #6E6E77}

.reportSection{padding: 20px}
.reportCarMainScore{border-radius: 14px;background: #222225;padding: 18px;display: flex;align-items: center;justify-content: space-between;gap: 10px}
.reportCarMainScore_info{display: flex;gap: 4px;align-items: center;text-align: left;font-size: 13px;color: rgba(255, 255, 255, 0.6)}
.reportCarMainScore_info>img{min-width: 12px}
.reportCarMainScore_title{font-size: 15px;font-weight: 700;margin: 7px 0 0}
.reportCarMainScore_score{font-size: 24px}
.reportCarMainScore + .reportCarImgBox{padding: 20px 30px 30px}
.reportCarImgCaption{text-align: center;display: flex;flex-direction: column;align-items: center;justify-content: flex-start;gap: 6px;font-size: 11px;font-weight: 500;color: #44D4A3;margin: 0 0 10px}
.reportCarImgBox>img{width: 100%}
.reportCarScoreBox{display: flex;justify-content: space-between;gap: 8px}
.reportCarScoreItem{border-radius: 14px;background: #222225;padding: 18px;width: calc(50% - 4px);text-align: left}
.reportCarScoreItem_name{display: flex;gap: 4px;align-items: center;text-align: left;font-size: 14px}
.reportCarScoreItem_name img{min-width: 12px}
.reportCarScoreItem_score{font-size: 20px;margin: 4px 0 0}
.reportSection_titleBox{margin: 0 0 10px}
.reportSection_title{font-size: 16px;font-weight: 700}
.reportDetailInfoBox{background: #222225;border-radius: 14px}
.reportDetailInfoBox + .reportDetailInfoBox{margin: 10px 0 0}
.reportDetailInfo_titleArea{text-align: left;width: 100%;padding: 16px 0;padding: 18px 20px 14px}
.reportDetailInfo_titleArea.sample_report_title{cursor: auto}
.reportDetailInfo_titleBox{display: flex;align-items: center;gap: 6px}
.reportDetailInfo_titleBox>img{min-width: 16px;width:16px}
.reportDetailInfo_scoreBox{display: flex;align-items: center;justify-content: flex-end;text-align: right;gap: 12px}
.reportDetailInfo_score{font-size: 16px;font-weight: 700;color: #73F7CA}
.reportDetailInfo_scoreBox>img{min-width: 11px;transition: transform 0.2s;opacity: 0.5}
.reportDetailInfoBox.active .reportDetailInfo_scoreBox>img{transform: rotate(180deg)}
.reportDetailInfo_contents{padding: 0 20px 20px;display: none}
.reportDetailInfo_subTitle{font-size: 15px;font-weight: 700;color: rgba(255, 255, 255, 0.8);margin: 0 0 9px}
.reportDetailInfo_text{font-size: 15px;color: rgba(255, 255, 255, 0.8)}
.reportDetailInfo_imgBox{display: flex;gap: 20px}
.reportDetailInfoImg{width: 100%;position: relative;border-radius: 8px;overflow: hidden;padding: 0 0 100%}
.reportDetailInfoImg_caption{padding: 4px 8px;background: #464650;position: absolute;right: 5px;bottom: 12px;border-radius: 4px;font-size: 13px;font-weight: 700}
.reportDetailInfoImg_caption.gColor{background: #73F7CA;color: #19191B}
.reportDetailInfo_title{font-size: 14px;font-weight: 700}
.reportDetailInfo_titleCaption{font-size: 13px;color: rgba(255, 255, 255, 0.6);margin: 2px 0 0}

.reportDetailInfo_chart{border-radius: 100px;width: 100%;height: 10px;background: #19191B;margin: 0 0 16px}
.reportDetailInfo_chart span{width: 50%;display: block;border-radius: 100px;height: 100%;background: linear-gradient(90deg, #2C8B9A 10%, #73F7CA 100%)}
.reportDetailInfo_titleInfo{display: flex;align-items: center;justify-content: space-between;gap: 10px}

.reportDetailInfo_comment{font-size: 14px;margin: 4px 0 20px;color:rgba(255, 255, 255, 0.8)}

.reportCarInfoSlide .reportCarImgBox{width: 68.05%;margin: 20px auto 30px}
.reportCarInfo{margin: 30px 0 0;background: #222225;border-radius: 12px;padding: 15px}
.reportCarInfo + .reportCarInfo{margin: 10px 0 0}
.reportCarInfo_name{font-size: 15px;font-weight: 500}
.reportCarInfo_itemBox{display: flex;align-items: flex-start;justify-content: space-between;margin: 10px 0 0;flex-wrap: wrap}
.reportCarInfo_item{display: flex;align-items: center;gap: 4px;width: calc(50% - 20.5px);min-width: calc(50% - 20.5px);justify-content: space-between}
.reportCarInfo_itemBox>span{display: block;width: 1px;height: 10px;background: #424243;margin: 4px 20px 0}
.reportCarInfo_item_name{font-size: 14px;color: rgba(255, 255, 255, 0.8);letter-spacing: -0.5px}
.reportCarInfo_item_value{font-size: 14px;letter-spacing: -0.5px;color:#73F7CA}
.reportCarInfo_item_value.rColor{color:#FF0000}
.reportCarInfo_item_value.yColor{color:#FF9D00}

.reportAutolife_chart{margin: 20px auto 0;list-style-type: none;overflow: hidden;position: relative;width: 100%;height: auto;padding: 0 0 calc(50% - 1px)}
.reportAutolife_chart::before{position: absolute;content: '';width: inherit;height: 100%;left: 0;top: 0;box-sizing: border-box;border: 30px solid rgba(246, 246, 246, 0.1);border-bottom: none;border-top-left-radius: calc(100vw - 120px - 40px - 40px);border-top-right-radius: calc(100vw - 120px - 40px - 40px)}
.reportAutolife_chart span{position: absolute;top: calc(100% + 1px);left: 0;width: inherit;height: calc(100% + 1px);border: 30px solid #73F7CA;border-top: none;border-bottom-left-radius: calc(100vw - 120px - 40px - 40px);border-bottom-right-radius: calc(100vw - 120px - 40px - 40px);transform-origin: 50% 0;transition: transform 1s}
.reportAutolife_chartBox{width: 100%;position: relative;max-width: calc(100% - 120px);margin: 0 auto;min-width: 200px}

.reportAutolife{background: linear-gradient(180deg, rgba(107, 234, 197, 0.4) 6%, rgba(25, 25, 27, 0.4) 100%);border-radius: 14px;padding: 25px 20px}
.reportAutolife_head{display: flex;align-items: center;justify-content: space-between;gap: 15px}
.reportAutolife_title{font-size: 15px;font-weight: 700}
.reportAutolife_title_carNumber{font-size: 13px;font-weight: 600}
.reportAutolife_rank{font-size: 14px;font-weight: 700;background: rgba(25, 25, 27, 0.5);padding: 5px 8px;border-radius: 8px}
.reportAutolife_rank span{color: #6EEEC6}
.reportAutolife_comment{margin: 20px 0 0;text-align: center;font-size: 14px;font-weight: 700}
.reportAutolife_btnBox{display: flex;align-items: center;justify-content: center;margin: 20px 0 0}
.reportAutolife_chart_info{display: flex;align-items: flex-end;justify-content: space-between;gap: 10px;margin: -30px 0 0}
.reportAutolife_chart_val{min-width:30px;color: rgba(255, 255, 255, 0.5);font-size: 13px;text-align: center;letter-spacing: -1.3px}
.reportAutolife_chart_score{font-size: 26px;font-weight: 700;text-align: center;color: #73F7CA}
.reportAutolife_chart_scoreCaption{font-size: 13px;color: #73F7CA;font-weight: 600;text-align: center;margin: 5px 0 0}
.reportAutolife_chart_scoreBox{width:calc(100% - 60px)}

.reportAutolife + .reportCarScoreBox{margin: 10px 0 0}

.reportcarImgIcon{fill:#44D4A3}
.reportcarImgIcon.yColor{fill:#FF9D00}
.reportcarImgIcon.rColor{fill:#FF0000}

.reportCarImg{position: relative;width: 68.05%;margin: 20px auto 30px}
.reportCarImg svg{position: absolute;top: 0;left: 0;width: 100%;height: 100%}
.reportCarImg>img{width: 100%}

/*recommended*/
.recommendedPopupItem{background: #07B174;border-top-left-radius: 20px;border-top-right-radius: 20px;padding: 30px 20px}
.recommendedPopupItem>img{margin: 0 auto 10px}
.recommendedPopupItem_title{text-align: center;font-size: 20px;font-weight: 700}
.recommendedPopupItem_text{font-size: 14px;text-align: center;margin: 6px 0 0}

.recommendedPage{background: #19191b;background: linear-gradient(180deg, #19191b 6%, #07b174 100%);padding: 0 20px}
.recommendedSection{padding: 60px 0 30px;text-align: center}
.recommendedSection + .recommendedSection{padding: 30px 0}
.recommendedSection_0_img{margin: 0 auto 20px;max-width: 200px;width: 100%}
.recommendedBadge{border-radius: 4px;background: #222225;display: inline-block;font-weight: 600;font-size: 11px;padding: 5px;line-height: 1;white-space: nowrap;margin: 0 0 12px}
.recommendedSection_0_subTitle{font-size: 12px;margin: 0 0 2px}
.recommendedSection_0_title{font-weight: 700;font-size: 17px}
.recommendedSection_0_text{font-size: 14px;margin: 16px 0 0}
.recommendedSection_1_img{max-width: 195px;width: 100%;margin: 0 auto 30px}
.recommendedSection_1_text{font-size: 13px}
.recommendedSection_btnBox{margin: 14px auto 0;max-width: 284px}
.pageBtn.dColor{background: #000;color: #fff;border-color: #000}
.pageBtn.dgColor{background: #167857;border-color: #167857;color: #fff}
.recommendedInfoBox{padding: 30px 0 0;border-top: 1px dashed rgba(255, 255, 255, 0.1)}
.recommendedInfo + .recommendedInfo{margin: 10px 0 0}
.recommendedInfo_name{font-size: 11px;font-weight: 700;margin: 0 0 2px}
.recommendedInfo_textBox{display: flex}
.recommendedInfo_textBox + .recommendedInfo_textBox{margin: 2px 0 0}
.recommendedInfo_textBox>span{width: 2px;height: 2px;border-radius: 100%;background: #fff;display: block;margin: 5px 5px 0}
.recommendedInfo_text{font-size: 11px;width: calc(100% - 12px)}
.recommendedProductBox{margin: 30px auto 0;max-width: 280px}
.recommendedProduct{display: flex;align-items: center;justify-content: space-between;border-radius: 6px;background: #000;padding: 7px 20px;margin: 6px 0 0}
.recommendedProduct_name{font-size: 14px;font-weight: 700}
.recommendedProduct_count{font-size: 14px;font-weight: 700;color: #73F7CA}

.fullModal{display: none}
.fullModal.active{display: block}

.pageBtn.awBtn{display: flex;align-items: center;justify-content: space-between;gap: 10px;background: #222225;border-color: #222225;color: #fff;padding: 5px 20px}

/*반응형*/
@media (max-width:1800px){

}
@media (max-width:1680px){
	
}
@media (max-width:1600px){

}
@media (max-width:1440px){

}
@media (max-width:1366px){

}
@media (max-width:1280px){

}
@media (max-width:1152px){

}
@media (max-width:1024px){

}
@media (max-width:960px){
    
}
@media (max-width: 720px){
    
}
@media (max-width: 650px){
    
}
@media (max-width: 550px){
}
@media (max-width: 490px){
}
@media (max-width: 400px){
}
@media (max-width: 340px){
}
/*//*/